import { User, UserRole } from 'app/models/api/user';
import { ApiService, ParamsMap } from 'app/services/api/api.service';
import { map } from 'rxjs/operators';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { inject, Injectable } from '@angular/core';
import { WarningLevel, WarningType } from 'app/models/api/warning';

export enum WarnedUserAction {
    ignore = 'ignore',
    quarantine = 'quarantine',
    deleteAvatar = 'delete-avatar',
    deletePhotos = 'delete-photos',
    deleteUnderaged = 'delete-underaged',
}

export interface UserWarningMeta {
    moderateWarningUserCount: number;
    severeWarningUserCount: number;
    suspectedUserCount: number;
}

@Injectable({
    providedIn: 'root',
})
export class UserWarningApiService {
    private readonly apiService = inject(ApiService);
    private readonly responseParser = inject(ResponseParser);

    getWarnedUsers(
        warningLevel: WarningLevel,
        options: {
            page: { limit: number; createdBefore?: string };
            warningType?: WarningType;
            role?: UserRole;
        },
    ) {
        const params: ParamsMap = {
            page: {
                ...(options.page.createdBefore ? { 'created-before': options.page.createdBefore } : {}),
                limit: options.page.limit,
            },
        };
        if (options.warningType || options.role) {
            params.filter = {};
            if (options.warningType) {
                params.filter.warningType = options.warningType;
            }
            if (options.role) {
                params.filter.role = options.role;
            }
        }
        return this.apiService
            .get(`/gem/sitly-users/warned/${warningLevel}`, { params })
            .pipe(map(response => this.responseParser.parseObject<User[]>(response)));
    }

    getWarnedUsersStats() {
        return this.apiService.get('/gem/sitly-users/warned?meta-only=1').pipe(map(response => response.meta as UserWarningMeta));
    }

    postWarnedUsersAction(action: WarnedUserAction, userIds: string[]) {
        return this.apiService.post('/gem/sitly-users/warned', {
            body: {
                action,
                ids: userIds,
            },
        });
    }

    deleteWarnedUsersPhotos(photoIds: string[]) {
        return this.apiService.post('/gem/sitly-users/warned', {
            body: {
                action: WarnedUserAction.deletePhotos,
                photoIds,
            },
        });
    }

    ignoreWarnings(warningIds: string[]) {
        return this.apiService.post('/gem/sitly-users/warned', {
            body: {
                action: WarnedUserAction.ignore,
                warningIds,
            },
        });
    }
}
